<template>

  <b-row @click="closeEverything"  style="" class="overlay"></b-row>

</template>

<script>
export default {
  name: "the-overlay",

  methods: {
    closeEverything() {
      this.$store.commit("closeEverything");
    }
  },

  computed: {
    
    modalActive() {
      return this.$store.state.modalActive ? true : false;
    },

  }
};

</script>

<style scoped>

  @media screen and (max-width: 768px) {

    .overlay {
      background-color: rgba(0, 0, 0, 0.8);
      position: fixed;
      z-index: 300;
    }

  }

  @media screen and (min-width: 769px) {

    .overlay {
      background-color: rgba(0, 0, 0, 0.8);
      position: absolute;
      z-index: 80;
    }  

  }

</style>
