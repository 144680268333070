<template>
  <div style="width: 100%;">
    <slot></slot>
    <div @click="closeEverything" style="width: 100%; height: 100%;"></div>
  </div>
</template>

<script>

export default {
  name: "the-modal",

  components: {
    
  },

  methods: {
    closeEverything() {
      this.$store.commit("closeEverything");
    }
  }

};
</script>

<style scoped>



</style>
