<template>
  <b-row style="background-color: white; height: 40px; overflow-x: hidden;" class="mobile-top-padding">
    <nav class="admin-nav d-flex w-100 col-12 no-padding justify-content-center" style="overflow-x: hidden;">
      <div class="d-flex align-items-center" style="max-width: 1200px;">
        <router-link class="nav-link text-decoration-none" to="/dashboard">Dashboard</router-link>
        <router-link class="nav-link text-decoration-none" style="text-align: center;" to="/categories">Categories
        </router-link>
        <router-link class="nav-link text-decoration-none" to="/vendors">Vendors</router-link>
        <router-link class="nav-link text-decoration-none d-none d-md-block" to="/inquiries">Inquiries</router-link>
        <router-link class="nav-link text-decoration-none d-none d-md-block" to="/events">Events</router-link>
        <router-link class="nav-link text-decoration-none d-none d-md-block" to="/newsletters">Newsletters</router-link>
        <router-link class="nav-link text-decoration-none d-none d-md-block" to="/log">Activity Log</router-link>
        <router-link class="nav-link text-decoration-none d-none d-md-block d-sm-none" to="/logout">
          <b-icon-box-arrow-right style="padding-top: 2px; margin-right: 4px;"></b-icon-box-arrow-right>Logout
        </router-link>
      </div>
    </nav>
  </b-row>
</template>

<script>
export default {
  name: 'the-admin-bar',

  components: {
    // BIconPhone,
  }
  
}
</script>

<style scoped>

  .admin-nav a {
    color: rgb(66, 66, 66);
    /* padding-left: 10px;
    padding-right: 30px; */
  }

  .admin-nav a:hover {
    color: rgb(23, 92, 142) !important;
    font-weight: 600;
  }

  .router-link-active {
    color: rgb(23, 92, 142) !important;
    font-weight: 600;
  }
  
  @media screen and (max-width: 768px) {
    .mobile-top-padding {
      padding-top: 65px;
    }
  }

  @media screen and (min-width: 769px) {

  }


</style>
