<template>
  <b-row :class="showGradient ? 'gradient' : ''">

    <!-- Desktop footer -->
    <b-col cols=12 class="text-center mt-4 d-none d-md-block">
      <ul class="small">
        <li><a href="#" @click.prevent="openSupport()">{{ 'dealer support' | capitalizeAll }}</a></li>
        <li class="text-secondary">|</li>
        <li><a :href="`tel:+${ this.$store.state.data.supportPhone }`">{{ this.$store.state.data.supportPhone }}</a></li>
        <li class="text-secondary">|</li>
        <li><a :href="`mailto:${ this.$store.state.data.supportEmail }`">{{ this.$store.state.data.supportEmail | capitalizeAll }}</a></li>
        <!-- <li class="text-secondary">|</li>
        <li>
          <router-link to="/frequently-asked-questions">{{ 'faq' | capitalizeAll }}</router-link>
        </li> -->
        <!-- <li class="text-secondary">|</li> -->
        <!-- <li v-show="!loggedIn">
          <router-link to="/login">{{ 'login' | capitalizeAll }}</router-link>
        </li>
        <li v-show="loggedIn">
          <router-link to="/logout">{{ 'logout' | capitalizeAll }}</router-link>
        </li> -->
      </ul>
      <p class="small mt-4">&copy; {{ year }} Acura<br>All information contained herein applies to U.S. dealers only.</p>
    </b-col>

    <!-- Mobile footer -->
    <b-col cols=12 class="text-center d-md-none small footer-margin-top">

      <a class="p-4" href="#" @click.prevent="openSupport()">{{ 'dealer support' | capitalizeAll }}</a><br>
      <hr>

      <a :href="`tel:+${ this.$store.state.data.supportPhone }`">{{ this.$store.state.data.supportPhone }}</a><br>
      <hr>

      <a :href="`mailto:${ this.$store.state.data.supportEmail }`">{{ this.$store.state.data.supportEmail | capitalizeAll }}</a><br>
      <hr>
      
      <!-- <router-link to="/frequently-asked-questions">{{ 'faq' | capitalizeAll }}</router-link><br> 
      <hr> -->

      <!-- <router-link to="/login" v-show="!loggedIn">{{ 'login' | capitalizeAll }}</router-link>
      <hr v-show="!loggedIn">

      <router-link to="/logout" v-show="loggedIn">{{ 'logout' | capitalizeAll }}</router-link><br>
      <hr v-show="loggedIn"> -->

      <p class="small mt-4">&copy; {{ year }} Acura<br>All information contained herein applies to U.S. dealers only.</p>

    </b-col>
  </b-row>
</template>

<script>

export default {

  name: 'the-footer',

  data() {
    return {
      year: new Date().getFullYear()
    };
  },

  methods: {
    openSupport() {
      window.scrollTo(0,0);
      this.$store.commit("toggleModal");
      this.$store.commit("toggleSupport");
    },
  },

  computed: {

    showGradient(){
      if(this.$route.name == 'Home'){
        return true;
      } else {
        return false;
      }
    },

    loggedIn(){
      return this.$store.getters.loggedIn;
    }
  }

}

</script>

<style scoped>

  a {
    color: #212529;
  }

  a:hover {
    text-decoration: none;
    color: #e82c2a;
  }

  .gradient {
    background-image: url("../assets/footer_gradient.png");
    background-repeat: repeat-x;
  }

  ul {
    margin-top: 30px;
  }

  li {
    display: inline;
    margin-left: 20px;
    margin-right: 20px;
    list-style-type: none;
  }

  @media screen and (max-width: 768px) {

    li {
      display: block;
    }

    .footer-margin-top {
      margin-top: 80px;
    }
  }

</style>