import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import VueGtag from "vue-gtag";
import store from "./store";
import axios from 'axios'
import Vuex from 'vuex'
import './registerServiceWorker'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Meta from 'vue-meta'
// import Dayjs from 'vue-dayjs';

Vue.use(Vuex)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Meta)
// Vue.use(Dayjs)
// Vue.use(require('vue-moment'));

Vue.prototype.$axios = axios

Vue.config.productionTip = false

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('capitalizeAll', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.toUpperCase()
})

// Vue.use(VueGtag, {
//   config: { id: "UA-168269136-1" },
// });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
