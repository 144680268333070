<template>
  <div>
    <b-icon-x @click="toggleMobileNav" v-if="mobileNavActive" style="width: 50px; height: 50px;" class=""></b-icon-x>
    <b-icon-list @click="toggleMobileNav" v-else style="width: 50px; height: 50px;"></b-icon-list>
  </div>
</template>

<script>

export default {
  name: 'the-hamburger',

  data() {
    return {
      
    }
  },

  methods: {
    toggleMobileNav(){
      this.$store.commit("toggleMobileNav");
    }
  },

  computed: {
    mobileNavActive(){
      return this.$store.state.mobileNavActive;
    }
  }



}

</script>

<style scoped>

</style>
